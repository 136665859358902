import moment, { Moment } from 'moment';
import { AbsoluteValue } from '../constants/typedef';
import { DateType } from '../models/date';
import { ErrorWithError, ErrorWithErrorsArray, ErrorWithMessage } from '../models/error/error';
import { Stories } from '../models/stories';
type AnyObject = { [key: string]: any };

const utilsService = {
    isEqual,
    isBirthdateValid,
    convertUTCDateToLocalDate,
    convertLocalDateToUTCDate,
    constructUrlWithParams,
    getDateTimeString,
    getDateString,
    getDate,
    validateEmail,
    validatePhoneNumber,
    isTodayWithinDateRange,
    isBeforeToday,
    formatNumber,
    initTransition,
    getCurrentStory,
    decamelize,
    replaceNumberInput,
    toCamelCase,
    appendToFormData,
    getErrorMessage,
    checkHasAnyNotEmptyObj,
    checkHasAnyEmptyObj,
    toMoment,
    toDate,
    formatDay,
    checkIsAnyEmptyKindOfData,
    checkIfAnyTwoStringFieldsHaveValue,
    checkFirstEmptyField,
    handleShareClick,
    getLastStep,
    isEmpty,
    cleanObject,
    filterChangedData,
    detectYouTubeUrl,
    hasKey,
    hasKeys,
    convertToMB,
    getAppDomain,
    normalizeDomain,
    isUrl,
    toSnakeCase,
    flexibleFormatPhoneNumber,
};

export const MIN_DATE_TIME = new Date(1970, 0, 1, 0, 0, 0);
const BASE_URL = process.env.BASE_URL;

function hasKey<T extends object>(obj: T, key: keyof any): boolean {
    return key in obj;
}

function flexibleFormatPhoneNumber(phoneNumber: string): string {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length <= 6) {
        return cleaned;
    }
    let result = '';
    let index = 0;
    result += cleaned.substring(index, index + 3);
    index += 3;
    while (index < cleaned.length) {
        const nextGroupLength = cleaned.length - index > 4 ? 4 : cleaned.length - index;
        result += '-' + cleaned.substring(index, index + nextGroupLength);
        index += nextGroupLength;
    }
    return result;
}

function getAppDomain(): string {
    return process.env.APP_URL || 'joysam.co';
}

function isUrl(url: string): boolean {
    return /https?:\/\/[^\s/$.?#].[^\s]*/.test(url);
}

function convertToMB(bytes: number) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
}

function normalizeDomain(domain: string) {
    return domain.startsWith('www.') ? domain.slice(4) : domain;
}

function hasKeys<T extends object>(obj: T, keys: (keyof T)[]): boolean {
    return keys.every((key) => key in obj);
}
function filterChangedData(newData: any, existingData: any) {
    const changedData: any = {};

    for (const key in newData) {
        if (Array.isArray(newData[key]) && Array.isArray(existingData[key])) {
            const arrayChanges = newData[key].filter((item: any, index: number) => !utilsService.isEqual(item, existingData[key][index]));
            if (arrayChanges.length > 0) {
                changedData[key] = arrayChanges;
            }
        } else if (typeof newData[key] === 'object' && typeof existingData[key] === 'object') {
            const objectChanges = filterChangedData(newData[key], existingData[key] ?? {});
            if (Object.keys(objectChanges).length > 0) {
                changedData[key] = objectChanges;
            }
        } else if (!utilsService.isEqual(newData[key], existingData[key])) {
            changedData[key] = newData[key];
        }
    }

    return changedData;
}

function formatNumber(value: number): string {
    if (value?.toString() && typeof value === 'number') {
        return value.toLocaleString('en-US');
    }
    return '0';
}

function detectYouTubeUrl(url: string): boolean {
    const youTubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return youTubeRegex.test(url);
}

function getDate(utcDateTime: string): Date {
    //'yyyy-MM-dd HH:mm:ss'
    if (utcDateTime.length === 19 && utcDateTime[10] === ' ') {
        const curDate = utcDateTime.substring(0, 10) + 'T' + utcDateTime.substring(11, 19) + '.000Z';
        return new Date(curDate);
    }

    if (utcDateTime.length > AbsoluteValue.Number) {
        return new Date(utcDateTime);
    }

    return MIN_DATE_TIME;
}

function replaceNumberInput(value: string): any {
    const regex = /[^0-9.]/g;
    return value.replace(regex, '');
}

function getLastStep(stepObject: any) {
    const keys = Object.keys(stepObject);

    if (keys.length === 0) return null;

    const lastKey = keys[keys.length - 1];

    const lastStepObject = stepObject[lastKey];

    if (typeof lastStepObject === 'object' && lastStepObject !== null) {
        return getLastStep(lastStepObject);
    }

    return lastStepObject;
}

function checkIsAnyEmptyKindOfData(data: any): boolean {
    const requiredFields = Object.keys(data);
    for (const field of requiredFields) {
        if (Array.isArray(data[field]) && data[field].length === 0) {
            return false;
        } else if (typeof data[field] === 'object' && data[field] !== null && !Object.keys(data[field]).length) {
            return false;
        } else if (data[field] === null || data[field] === undefined || (typeof data[field] === 'string' && data[field].length === 0)) {
            return false;
        }
    }
    return true;
}

function isEmpty(value: any): boolean {
    return (
        value === null ||
        value === undefined ||
        value === '' ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && !Array.isArray(value) && !(value instanceof File) && Object.keys(value).length === 0)
    );
}

function cleanObject(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(cleanObject).filter((item) => !isEmpty(item));
    } else if (typeof obj === 'object' && obj !== null) {
        if (obj instanceof File) {
            return obj; // Keep File objects as they are
        }
        return Object.entries(obj).reduce((acc, [key, value]) => {
            const cleanedValue = cleanObject(value);
            if (!isEmpty(cleanedValue)) {
                acc[key] = cleanedValue;
            }
            return acc;
        }, {} as AnyObject);
    }
    return obj;
}

function checkFirstEmptyField(data: { [key: string]: any }): string | null {
    const requiredFields = Object.keys(data);
    for (const field of requiredFields) {
        if (Array.isArray(data[field]) && data[field].length === 0) {
            return field;
        } else if (typeof data[field] === 'object' && data[field] !== null && !Object.keys(data[field]).length) {
            return field;
        } else if (data[field] === null || data[field] === undefined || (typeof data[field] === 'string' && data[field].length === 0)) {
            return field;
        }
    }
    return null;
}

function checkIfAnyTwoStringFieldsHaveValue(data: any): boolean {
    const requiredFields = Object.keys(data);
    let nonEmptyFieldCount = 0;

    for (const field of requiredFields) {
        if (typeof data[field] === 'string' && data[field].length > 0) {
            nonEmptyFieldCount++;
        }

        if (nonEmptyFieldCount >= 2) {
            return true;
        }
    }

    return false;
}

function formatDay(date: Moment, locale = 'en') {
    return moment(date).locale(locale).format('ddd');
}

function isBeforeToday(startDate: Moment): boolean {
    const today = moment();
    return startDate?.isBefore(today) || startDate?.isSame(today);
}

function isTodayWithinDateRange(startDate: DateType, endDate: DateType): boolean {
    const today = moment();
    const start = toMoment(startDate);
    const end = toMoment(endDate);

    if (!start || !end) {
        return false;
    }

    return today.isBetween(start, end, undefined, '[]');
}

function convertUTCDateToLocalDate(date: DateType): Date | null {
    const momentDate = toMoment(date);
    if (!momentDate) {
        return null;
    }
    return momentDate.local().toDate();
}

function convertLocalDateToUTCDate(date: DateType): Date | null {
    const momentDate = toMoment(date);
    if (!momentDate) {
        return null;
    }
    return momentDate.utc().toDate();
}

function getDateTimeString(dateTime: DateType): string {
    const momentDate = toMoment(dateTime);
    if (!momentDate) {
        return '';
    }
    return momentDate.format('YYYY-MM-DD HH:mm:ss');
}

function getDateString(dateTime: DateType): string {
    const momentDate = toMoment(dateTime);
    if (!momentDate) {
        return '';
    }
    return momentDate.format('YYYY-MM-DD');
}

function validateEmail(email: string): boolean {
    // eslint-disable-next-line no-useless-escape
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
}

function validatePhoneNumber(phoneNumber: string): boolean {
    //+(123) - 456-78-90
    // eslint-disable-next-line no-useless-escape
    return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phoneNumber);
}

function decamelize(str: string): string {
    return str.replace(/([a-z\d])([A-Z])/g, '$1_$2').toLowerCase();
}

function toCamelCase(obj: any, options: { deep?: boolean } = {}): any {
    if (Array.isArray(obj)) {
        return obj.map((v) => (options.deep ? toCamelCase(v, options) : v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result: any, key: string) => {
            const camelKey = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
            result[camelKey] = options.deep ? toCamelCase(obj[key], options) : obj[key];
            return result;
        }, {});
    }
    return obj;
}

function toSnakeCase(obj: any, options: { deep?: boolean } = {}): any {
    if (Array.isArray(obj)) {
        return obj.map((v) => (options.deep ? toSnakeCase(v, options) : v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result: any, key: string) => {
            const snakeKey = key.replace(/([A-Z])/g, (group) => `_${group.toLowerCase()}`);
            result[snakeKey] = options.deep ? toSnakeCase(obj[key], options) : obj[key];
            return result;
        }, {});
    }
    return obj;
}

async function appendToFormData<T extends object>(data: T, useDecamelize: boolean = true): Promise<FormData> {
    const formData = new FormData();

    function processFormData(key: string, value: any) {
        if (value instanceof File) {
            formData.append(key, value);
        } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
                processFormData(`${key}[${index}]`, item);
            });
        } else if (value !== null && typeof value === 'object') {
            Object.keys(value).forEach((subKey) => {
                const newKey = useDecamelize ? decamelize(subKey) : subKey;
                processFormData(`${key}[${newKey}]`, value[subKey]);
            });
        } else {
            formData.append(key, value != null ? (typeof value == 'boolean' ? value : value.toString()) : '');
        }
    }

    Object.keys(data).forEach((key) => {
        const value = (data as any)[key];
        const newKey = useDecamelize ? decamelize(key) : key;
        processFormData(newKey, value);
    });

    return formData;
}

const isErrorWithMessage = (error: any): error is ErrorWithMessage => {
    return error && typeof error?.message === 'string';
};

const isErrorWithError = (error: any): error is ErrorWithError => {
    return error && typeof error?.error === 'string';
};

const isErrorFor422Code = (error: any): error is ErrorWithErrorsArray => {
    return error?.errors && typeof error.errors === 'object' && Object.keys(error.errors)?.length > 0;
};

function getErrorMessage(error: any): string | any {
    if (!error?.data?.status && error?.code !== 'ERR_CANCELED' && error?.code === 'ERR_NETWORK') {
        return '';
    }
    if (error?.status == 410) {
        return error?.data?.msg;
    }
    if (error?.status == 403) {
        return error?.data?.msg;
    }
    if (isErrorFor422Code(error)) {
        return error?.errors[Object.keys(error.errors)[0]]?.[0];
    }
    if (isErrorWithMessage(error)) {
        return error?.message;
    } else if (isErrorWithError(error)) {
        return error.error;
    } else {
        return error;
    }
}

function checkHasAnyNotEmptyObj(object: Object): boolean {
    return Object.values(object).some((error) => error !== null && error !== undefined && error !== '');
}

function checkHasAnyEmptyObj(object: Object): boolean {
    return Object.values(object).some((value) => {
        return value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0);
    });
}

function toMoment(date: DateType): Moment | null {
    if (!date) {
        return null;
    }
    return moment(date);
}

function toDate(date: DateType): Date | null {
    if (!date) {
        return null;
    }
    return moment(date).toDate();
}

function handleShareClick(text: any, callback: () => void): void {
    navigator.clipboard.writeText(text).then(
        () => {
            callback();
        },
        (err) => {
            console.error('Could not copy text: ', err);
        }
    );
}

export default utilsService;

export function detectDeviceClient() {
    if (typeof window === 'undefined') {
        return 'unknown'; // Server-side rendering situation
    }

    const ua = window.navigator.userAgent.toLowerCase();

    if (/android/i.test(ua)) {
        return 'android';
    }

    if (/iphone|ipad|ipod/i.test(ua)) {
        return 'ios';
    }

    if (/win|mac|linux|x11/i.test(ua)) {
        return 'pc';
    }

    return 'unknown';
}

function isEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1?.length !== keys2?.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

function isBirthdateValid(birthdate: Moment | string | null): boolean {
    const today = moment().startOf('day');

    if (!birthdate) {
        return false;
    }

    let birthdateMoment;

    if (moment.isMoment(birthdate)) {
        birthdateMoment = birthdate.startOf('day');
    } else {
        birthdateMoment = moment(birthdate, 'YYYY-MM-DD').startOf('day');
    }

    return birthdateMoment.isSameOrAfter(today);
}

function constructUrlWithParams(basePath: string, params: Record<string, string | number | null | undefined>): string {
    const url = new URL(basePath, BASE_URL);

    Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
            url.searchParams.append(key, String(value));
        }
    });

    return url.toString();
}

function initTransition(spanId: string) {
    const spanTransition = document.getElementById(spanId);
    spanTransition?.classList.add('story-hover-transition');
}

function getCurrentStory(stories: Stories[], current: Stories) {
    const currentStoryIndex = stories?.findIndex((story) => story.id === current.id);
    return {
        currentStoryIndex,
        currentStory: stories?.[currentStoryIndex],
    };
}
